<template>
	<div class="sche-list">
		<el-button type="primary" @click="dialogTableVisible2btn">添加排班</el-button>
		<el-calendar>
			<template slot="dateCell" slot-scope="{date, data}">
				<div :class="data.isSelected ? 'is-selected' : ''" @click="show(data.day)">
					{{ data.day.split('-').slice(1).join('-') }}
					<p class="tip" v-for="(item,index) in list" :key="item.id" v-if="item.date==data.day">
						{{item.period}}&nbsp;(<font color="red">{{item.nowNum}}&nbsp;</font>/&nbsp;{{item.num}})
					</p>
				</div>
			</template>
		</el-calendar>
		<!-- 		<el-dialog title="查看排班" :visible.sync="dialogTableVisible" :modal="false">
                    <table class="sch-table">
                        <tr>
                            <th>当前科室</th>
                            <td>{{showDetail.deptName}}</td>
                        </tr>
                        <tr>
                            <th>出诊日期</th>
                            <td>{{showDetail.date}}</td>
                        </tr>
                        <tr>
                            <th>出诊时间</th>
                            <td>
                                <p v-for="item in showDetail.period">{{Object.keys(item)[0]}} 看诊人数：<span>{{item[Object.keys(item)[0]]}}</span></p>
                            </td>
                        </tr>

                    </table>
                    <div class="btns">
                        <el-button size="mini" type="primary" @click="closeDialog">关闭</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="delPer(showDetail.id)">
                            <el-button slot="reference" class="sbtn-del" size="mini" type="danger">删除</el-button>
                        </el-popconfirm>
                    </div>
                </el-dialog> -->


		<el-dialog title="排班" :visible.sync="dialogTableVisible2" :modal="false">
			<table class="sch-table">
				<tr>
					<th>当前科室</th>
					<td>{{deptName}}</td>
				</tr>
				<tr>
					<th>选择出诊日期</th>
					<td>
						<el-date-picker :disabled="type=='updata'" type="dates" v-model="pdates" value-format="yyyy-MM-dd" placeholder="选择一个或多个日期">
						</el-date-picker>
					</td>
				</tr>
				<tr>
					<th>添加时间段</th>
					<td>
						<el-button type="success" @click="add">新增</el-button>
						<div class="ptime" v-for="(item,index) in ptime" :key="index">
							时间段：
							<!-- <el-input type="time" style="width: 20%;" v-model="item.stime"></el-input> -->

							<el-time-select v-model="item.stime"
											:picker-options="{
							 start: '06:00',
							 end: '24:00',
							  }"
											@change = "checkStime(index,item.stime)"
											placeholder="选择时间" style="width: 130px;"
							>
							</el-time-select>
							-

							<el-time-select v-model="item.etime"
											:picker-options="{
							 start: '06:00',
							 end: '24:00',
							 minTime: item.stime,
							  }"
											@change = "checkEtime(index,item.etime)"
											placeholder="选择时间" style="width: 130px;">
							</el-time-select>

							<!-- <el-input type="time" style="width: 20%;" v-model="item.etime"></el-input> -->
							看诊人数：
							<el-input type="number" style="width: 20%;" min="1" v-model="item.num"></el-input>
							<el-button class="sbtn-del" type="danger" @click="delTime(index,item)">删除</el-button>
						</div>
					</td>
				</tr>

			</table>
			<div class="btns">
				<el-button size="mini" type="primary" @click="submit">提交</el-button>

			</div>
		</el-dialog>


	</div>
</template>

<script>
    import { isEmpty } from '../../utils/util.js'
    export default {
        data() {
            return {
                deptno: 0,
                deptName: '',
                list: [],
                dialogTableVisible: false,
                dialogTableVisible2: false,
                showDetail: {},
                pdates: [],
                type:'add',
                ptime: [{
                    stime: "",
                    etime: "",
                    num: ""
                }]
            }
        },
        methods: {
            // 提交
            submit() {
                const that=this
                let flag = true
                this.ptime.forEach((o, i) => {
                    if (isEmpty(o.stime)) {
                    that.flag = false
                    this.$message.error('开始时间段不能为空!')
                } else if(isEmpty(o.etime)){
                    that.flag = false
                    this.$message.error('结束时间段不能为空!')
                } else if(Number(o.num) === 0 || isEmpty(o.num)){
                    that.flag = false
                    this.$message.error('看诊人数不能为空!')
                } else {
                    that.flag = true
                }
            })
            if(that.flag === true){
                if(that.type=='add'){
                    that.additem();
                }else if (that.type=='updata'){
                    that.updata();
                }
            }
            },
            // 添加方法
            additem(){
                let that = this;
                let query = {};

                let fperiod = {}; //格式化出诊时间格式
                this.ptime.forEach((o, i) => {
                    fperiod[o.stime + "-" + o.etime] = Number(o.num);
            });
                query.date = that.pdates;
                query.deptNo = this.deptno;
                query.period = fperiod;
                that.api("/admin/department/add_schedule", query).then(res => {
                    if (res.code == 200) {
                    this.dialogTableVisible2 = false;
                    that.getlist();
                }
            });
            },
            // 修改方法
            updata(){
                const that=this;
                var query = {};
                var ptime=that.ptime;
                for(var i=0; i<ptime.length; i ++){
                    ptime[i].period= ptime[i].stime +'-'+ ptime[i].etime;
                    ptime[i].num=Number(ptime[i].num);
                }
                query.date=that.pdates;
                query.dateNum=ptime;
                query.deptNo = that.deptno;

                that.api('/admin/department/edit_schedule',query).then(res=>{
                    if (res.code == 200) {
                    this.dialogTableVisible2 = false;
                    that.getlist();
                }
            })

            },


            // 点击添加排班
            dialogTableVisible2btn() {
                const that = this;
                that.type='add';
                that.pdates=[];
                that.ptime = [{
                    stime: "",
                    etime: "",
                    num: ""
                }]

                that.dialogTableVisible2 = true;
            },

            //处理多选日期value-format 属性 bug
            // handleDate(d){
            // 	let ndate = [];
            // 	d.forEach((o,i)=>{
            // 		let m = o.getMonth()+1;
            // 		let date = o.getDate();
            // 		ndate.push(o.getFullYear()+"-"+(m<10?'0'+m:m)+'-'+(date<10?'0'+date:date));
            // 	});

            // 	this.pdates = ndate;

            // },
            //增加出诊时间段
            add() {
                const that=this;
                this.ptime.push({
                    stime: "",
                    etime: "",
                    num: "",
                    deptno:that.deptno
                });
            },
            //删除出诊时间段
            delTime(index,item) {
                const that=this;
                let ids = [];
                ids.push(item.id);
                that.$confirm('您确认要删除此时间段么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api("/admin/department/delete_schedule", {
                        ids: ids
                    }).then(res => {
                        if (res.code == 200) {
                            that.ptime.splice(index, 1);
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    });
                }).catch(() => {
                    that.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //处理排班展示数据逻辑
            // show(date) {
            // 	const that=this;
            // 	this.showDetail.deptName = this.deptName;
            // 	this.showDetail.date = date;
            // 	this.showDetail.period = [];
            // 	this.showDetail.id = [];
            // 	this.list.forEach((o, i) => {
            // 		if (o.date == date) {
            // 			let perObj = {};
            // 			perObj[o.period] = o.num; // o.period排班时段   o.num排班时段的接诊人数
            // 			this.showDetail.period.push(perObj);
            // 			this.showDetail.id.push(o.id);
            // 		}
            // 	});
            // 	console.log(date,this.showDetail);
            // 	that.pdates=this.showDetail.date;


            // 	this.dialogTableVisible2 = true;
            // },


            //处理排班展示数据逻辑
            show(date) {
                const that=this;
                that.type='updata'
                that.ptime=[];
                that.list.forEach((o, i) => {
                    if (o.date == date) {
                    let a={}
                    a.stime=(o.period.split('-'))[0];
                    a.etime=(o.period.split('-'))[1];
                    a.num=o.num;
                    a.deptno=o.deptno;
                    a.id=o.id;
                    a.nowNum=o.nowNum;
                    a.date=o.date;
					a.period=o.period;
                    that.ptime.push(a)
                }
            });
                that.pdates=date;
                that.dialogTableVisible2 = true;
            },


            //删除排班（出诊）
            delPer(ids) {
                let that = this;
                that.api("/admin/department/delete_schedule", {
                    ids: ids
                }).then(res => {
                    if (res.code == 200) {
                    for (let i = that.list.length - 1; i > 0; i--) {
                        if (ids.indexOf(that.list[i].id) > -1) {
                            that.list.splice(i, 1);
                        }
                    }
                    that.dialogTableVisible = false;
                }
            });


            },
            //关闭查看排班对话框
            closeDialog() {
                this.dialogTableVisible = false;
            },
            //获取排班数据
            getlist() {
                const that = this;
                that.api("/admin/department/schedule_list", {
                    deptNo: this.deptno
                }).then(res => {
                    that.list = res.data;
            })
            },
            checkStime(index,value){
                for(var i=0;i<this.ptime.length-1;i++){
                    if (this.ptime[index]!==undefined&&index != i) {
                        if(this.judege(value) >= this.judege(this.ptime[i].stime)  &&   this.judege(value)<this.judege(this.ptime[i].etime) ){
                            this.$message.error('时间段不可重复!')
                            this.ptime[index].stime = ''
                            this.ptime[index].etime = ''
                            return ;
                        }
                    }
                }
            },
            checkEtime(index,value){
                for(var i=0;i<this.ptime.length-1;i++){
                    if (this.ptime[index]!==undefined&&index != i) {
                        if(this.judege(value) > this.judege(this.ptime[i].stime)  &&   this.judege(value)<=this.judege(this.ptime[i].etime) ){
                            this.$message.error('时间段不可重复!')
                            this.ptime[index].stime = ''
                            this.ptime[index].etime = ''
                            return ;
                        }
                    }
                }
            },
            judege (val) {
                let vals=val.split(":")
                return Number(vals[0])*60+Number(vals[1])
            }
        },
        created() {
            this.deptno = this.$route.query.no;
            this.deptName = this.$route.query.dn;
            this.getlist();
        }
    }
</script>

<style scoped>
	.sche-list /deep/ .el-calendar-day{
		min-height: 200px;
	}


	.is-selected {
		color: #1989FA;
	}

	.tip {
		font-size: 14px;
		padding-left: 2px;
		color: darkslategray;
		user-select: none;
	}

	.sch-table {
		border: 1px solid aliceblue;
		border-collapse: collapse;
		width: 100%;
	}

	.sch-table th,
	.sch-table td {
		border: 1px solid aliceblue;
		padding: 10px;
	}

	.sch-table th {
		width: 120px;
	}

	.btns {
		text-align: right;
		margin-top: 30px;
	}

	.ptime {
		margin: 15px 0;
	}
</style>
